import { Component, OnInit, OnDestroy, PLATFORM_ID } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;
  errorMessage: string;

  constructor(public readonly auth: AngularFireAuth) {
  }

  ngOnInit(): void { }

  async signInWithGoogle() {
    try {
      const user = await this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
      console.log(user);
    } catch (err) {
      console.log(err);
      this.errorMessage = err.message;
    }
  }

  async signInWithApple() {

  }

  async signInWithEmail() {
    try {
      const user = await this.auth.signInWithEmailAndPassword(this.email, this.password);
      console.log(user);
    } catch (err) {
      console.log(err);
      this.errorMessage = err.message;
    }
  }

  // async loginAnonymously() {
  //   const user = await this.auth.signInAnonymously();
  //   // TODO sign into offline app
  // }

  // logout() {
  //   this.auth.signOut();
  //   // TODO sign out of offline app
  // }
}
