import { EditorComponent } from './blog/editor.component';
import { BlogDashboardComponent } from './blog/dashboard.component';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { QuillModule } from 'ngx-quill';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { CoolSocialLoginButtonsModule } from '@angular-cool/social-login-buttons';
import { HomeComponent } from './home/home.component';
import { VerificationComponent } from './verification/verification.component';
import { DisputesComponent } from './disputes/disputes.component';

// image resizing
import * as QuillNamespace from 'quill';
let Quill: any = QuillNamespace;
import ImageResize from 'quill-image-resize-module';
Quill.register('modules/imageResize', ImageResize);

const firebaseConfig = {
  apiKey: "AIzaSyAW71A-EtGVF7vWXeOwTlw8BrB19IE03Js",
  authDomain: "book-bloom.firebaseapp.com",
  projectId: "book-bloom",
  storageBucket: "book-bloom.appspot.com",
  messagingSenderId: "197710943313",
  appId: "1:197710943313:web:d9b4f53905fceb33bed470",
  measurementId: "G-KZ6NPNMYCK"
};

@NgModule({
  declarations: [
    AppComponent,
    BlogDashboardComponent,
    EditorComponent,
    LoginComponent,
    DashboardComponent,
    HomeComponent,
    VerificationComponent,
    DisputesComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CoolSocialLoginButtonsModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic' /*, 'underline', 'strike'*/],   // toggled buttons
          ['blockquote', 'code-block'],
          [/*{ 'header': 1 }, { 'header': 2 }*/],               // custom button values
          [/*{ 'list': 'ordered' }, { 'list': 'bullet' }*/],
          [/*{ 'script': 'sub' }, { 'script': 'super' }*/], // superscript/subscript
          [/*{ 'indent': '-1' }, { 'indent': '+1' }*/],         // outdent/indent
          [/*{ 'direction': 'rtl' }*/],                     // text direction
          [/* { 'size': ['small', false, 'large', 'huge'] }*/],  // custom dropdown, false = normal
          // tslint:disable-next-line:object-literal-key-quotes
          [{ 'header': [1, 2, 3, 4, false] }],
          [/*{ 'color': [] }, { 'background': [] }*/],          // dropdown with defaults from theme
          [/*{ 'font': [] }*/],
          // tslint:disable-next-line:object-literal-key-quotes
          [{ 'align': [] }],
          [/*'clean'*/],
          ['link', 'image' /*, 'video'*/],
        ],
        imageResize: true,
      },
    })
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }
