<div class="container-fluid h-100">
  <div class="row h-100 pt-4">
    <div class="col">
      <div class="row">
        <i (click)="goBack()" type="button" class="fa fa-arrow-circle-left text-muted ms-3"
          style="font-size: 32px;"></i>
      </div>
      <div class="card mt-4 mx-3 p-4">
        <ul>
          <li>DON'T FORGET TO CLICK SAVE</li>
          <li>Font Styles
            <ul>
              <li>1 Title tag (h1)</li>
              <li>0-1 Subtitle tag (h2) - place under title</li>
              <li>Header tags (h3) - mainly for labelling major sections</li>
              <li>Sub-Header tags (h4) - mainly for labelling sub-sections</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-auto">
      <div class="row justify-content-between mb-3">
        <div class="col-2">
          <div (click)="save()" [class.disabled]="saving" class="w-100 btn btn-success text-white">{{saving ?
            'Saving':'Save'}}</div>
        </div>
        <div class="col-auto">
          <div class="text-muted">last saved {{dateSaved | date:'short'}}</div>
        </div>
      </div>
      <input type="file" style="display: none" id="fileInputField" />
      <div class="card bg-white h-100 px-4">
        <div class="mt-4">
          <div *ngIf="loading" class="row" style="width: 740px;">
            <div class="spinner-border text-primary m-auto" role="status"></div>
          </div>
          <quill-editor *ngIf="!loading" theme="bubble" (onEditorChanged)="changed($event)"
            (onEditorCreated)="created($event)" placeholder="Begin Writing..." format="json">
            <div quill-editor-toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-blockquote"></button>
                <button class="ql-code-block"></button>
              </span>
              <span class="ql-formats">
                <select class="ql-header">
                  <option selected></option>
                  <option value="1"></option>
                  <option value="2"></option>
                  <option value="3"></option>
                  <option value="4"></option>
                </select>
              </span>
              <span class="ql-formats">
                <select class="ql-align">
                  <option selected></option>
                  <option value="center"></option>
                  <option value="right"></option>
                  <option value="justify"></option>
                </select>
              </span>
              <span class="ql-formats">
                <button class="ql-link"></button>
                <button class="ql-image" data-bs-toggle="modal" data-bs-target="#imageUploadModal"></button>
              </span>
            </div>
          </quill-editor>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="row h-100">
        <div class="col mt-4 mx-5">
          NOTES
          <div>
            <textarea [(ngModel)]="newNote" name="note" class="form-control form-control-lg my-3"
              placeholder="..."></textarea>
            <div class="row justify-content-end">
              <div class="col-auto">
                <div (click)="addNote()" [class.disabled]="addingNote" class="btn btn-outline-primary btn-sm text-white">
                  {{addingNote ?
                  'Adding':'Add Note'}}</div>
              </div>
            </div>
          </div>

          <div *ngFor="let note of notes">
            <div class="mt-3">
              <div style="font-size: 16px;">{{note.text}}</div>
              <small class="text-muted">{{dateSaved | date:'short'}}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<!-- <div #imageUploadModal class="modal fade" id="imageUploadModal" tabindex="-1" aria-labelledby="imageUploadModal"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Insert Image</h5>
        <button id="closeImageUpload" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form class="mt-3">
          <div class="mb-4 input-group">
            <input [(ngModel)]="imageFile" id="fileUpload" name="fileUpload" type="file" class="form-control">
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="uploadImage()">
          Add Image
          <div *ngIf="addingImage" class="spinner-border" role="status"
            style="height: 16px; width: 16px; margin-left: 8px;"></div>
        </button>
      </div>
    </div>
  </div>
</div> -->