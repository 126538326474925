<div class="container-fluid h-100">
    <nav class="navbar navbar-dark">
        <div class="container-fluid ms-3">
            <a class="navbar-brand" href="#">
                Bloom Admin
                <span class="badge bg-dark" style="font-size: 8px; vertical-align: top;">BETA</span>
            </a>
        </div>
    </nav>
    <div class="row body-height">
        <div class="col-auto border-dark border-end pe-0">
            <ul class="nav flex-column hide-caret">
                <li *ngFor="let nav of navigator" class="nav-item">
                    <div type="button" class="nav-link border-end border-3" [class.active]="nav != current"
                        [class.border-transparent]="nav != current" [class.text-muted]="nav != current"
                        [class.active]="nav == current" [class.border-active]="nav == current"
                        [class.text-light]="nav == current" (click)="current = nav">{{nav}}</div>
                </li>
            </ul>
        </div>
        <div class="col">
            <app-home *ngIf="current == 'Home'"></app-home>
            <app-blog-dashboard *ngIf="current == 'Blog'"></app-blog-dashboard>
            <app-verification *ngIf="current == 'Verification'"></app-verification>
            <app-disputes *ngIf="current == 'Disputes'"></app-disputes>
        </div>
    </div>
</div>