import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-disputes',
  templateUrl: './disputes.component.html',
  styleUrls: ['./disputes.component.scss']
})
export class DisputesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
