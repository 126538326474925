<div>
    <div class="row">
        <div *ngIf="loading" class="spinner-border text-primary m-auto" role="status"></div>

        <div *ngFor="let blog of blogs | async" class="col-4 mb-4">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">{{blog['list-title']}}</h5>
                    <p class="card-text">{{blog['list-description']}}</p>
                    <div (click)="goToBlog(blog['id'])" type="button" class="btn btn-secondary text-white">View</div>
                    <div (click)="removeEntry(blog['id'])" type="button" class="btn btn-danger text-white" style="margin-left: 12px;">Remove</div>
                </div>
            </div>
        </div>
    </div>
    <footer>
        <div class="row h-100">
            <div class="col align-self-center">
                <div type="button" class="btn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">New Blog</div>
            </div>
        </div>
    </footer>
</div>
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Blog Entry Setup</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form class="mt-3">
            <div class="mb-3">
                <label for="listTitle" class="form-label">Entry Name</label>
                <input [(ngModel)]="listTitle" name="listTitle" class="form-control form-control"
                    placeholder="...">
            </div>
            <div class="mb-4">
                <label for="listDescription" class="form-label">Entry Description</label>
                <input [(ngModel)]="listDescription" name="listDescription" class="form-control form-control"
                    placeholder="...">
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary text-white" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="addEntry()">Add</button>
      </div>
    </div>
  </div>
</div>