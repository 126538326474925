import { ThisReceiver } from '@angular/compiler';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { DatabaseReference } from '@angular/fire/database/interfaces';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { QuillModules, defaultModules } from 'ngx-quill';
import { UploadTaskSnapshot } from '@angular/fire/storage/interfaces';
import * as $ from 'jquery';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['editor.component.scss']
})
export class EditorComponent implements OnInit {
  saving = false;
  addingNote = false;

  loading = true;
  content: any;
  dateSaved: Date;
  blogRef: DatabaseReference;

  newNote: string;
  notes: any[] = [];

  quillFile: any;
  meQuillRef: any;


  constructor(public db: AngularFireDatabase,
    public storage: AngularFireStorage,
    public router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder) {
    this.route.params.subscribe(params => {
      this.blogRef = this.db.database.ref(`blogs/${params['id']}`);
    });
    // this.blogs = db.list('blogs').valueChanges();

    // this.blogs.subscribe(() => this.loading = false);
  }

  data;
  ngOnInit(): void {
    this.blogRef.once('value').then((data) => {
      this.data = data.val();
      if (this.data.notes != null) this.notes = this.data.notes;
      if (this.data.content != null) this.content = this.data.content;
      if (this.data.dateSaved != null) this.dateSaved = new Date(Date.parse(this.data.dateSaved));
      this.loading = false;
    });
  }

  file: File;
  fileChange(file) {
    this.file = file.target.files[0];
    console.log(this.file.name);
  }

  created(editor) {
    this.meQuillRef = editor;
    const toolbar = this.meQuillRef.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler);
    if (this.content != null) editor.setContents(this.content.content, this.content.delta);
  }

  imageHandler = (image, callback) => {
    const input = <HTMLInputElement>document.getElementById('fileInputField');
    document.getElementById('fileInputField').onchange = () => {
      let file: File;
      file = input.files[0];
      // file type is only image.
      console.log(file);
      if (/^image\//.test(file.type)) {
        let uid = this.makeRandom();
        console.log(uid);
        const reader  = new FileReader();
        reader.onload = () => {
          console.log('load');
          console.log( reader.result.toString().split(',')[1]);
          this.storage.upload(`blogs/${this.data['list-title']}/${uid}`, file).then((x: UploadTaskSnapshot) => {
            x.ref.getDownloadURL().then((url) => {
              console.log(url);
              const range = this.meQuillRef.getSelection();
              const img = `<img src="${url}"/>`;
              console.log(img);
              this.meQuillRef.clipboard.dangerouslyPasteHTML(range.index, img);
            });
          });
        };
        reader.readAsDataURL(file);
      } else {
        alert('You could only upload images.');
      }
    };

    input.click();
  }

  makeRandom() {
    let text = "";
    for (let i = 0; i < this.lengthOfCode; i++) {
      text += this.possible.charAt(Math.floor(Math.random() * this.possible.length));
    }
    return text;
  }
  possible = "ABCDEFGHIJKLMNOPQRSTUVWXYabcdefghijklmnopqrstuvwxyzZ1234567890*&^%$#@!`";
  lengthOfCode = 10;

  changed(e) {
    if (e.content != null) {
      this.content = {
        "content": e.content,
        "delta": e.delta,
        "html": e.html,
        "text": e.text
      };
    }
  }

  save() {
    if (this.saving) return;
    this.saving = true;
    this.blogRef.update({ "content": this.content, "dateSaved": new Date() }).then((data) => {
      this.saving = false;
    });
  }

  addNote() {
    if (this.addingNote) return;
    this.addingNote = true;
    this.blogRef.update({ "notes": [{ "text": this.newNote, "date": new Date() }, ...this.notes] }).then((data) => {
      this.addingNote = false;
      this.notes = [{ "text": this.newNote, "date": new Date() }, ...this.notes];
    });
  }

  goBack() {
    this.router.navigate(['dashboard']);
  }
}

// byPassHTML(html: string): SafeHtml {
//   console.log(html);
//   return this.sanitizer.bypassSecurityTrustHtml(html);
// }
