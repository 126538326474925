import { Component, ViewEncapsulation } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  title = 'admin';

  constructor(public readonly auth: AngularFireAuth) { }

  public isLoggedIn(): boolean {
    return !!this.auth.currentUser;
  }
}
